var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "204",
        height: "28",
        viewBox: "0 0 204 28",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
      on: { click: _vm.handleClick },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_24681_35469)" } }, [
        _c("path", {
          attrs: {
            d: "M27.6173 0.0322266H0V5.45917H27.0662V10.9557H16.2536V16.4132H27.6173C30.2797 16.4132 32.4382 14.2326 32.4382 11.5428V4.90255C32.4382 2.21274 30.2797 0.0322266 27.6173 0.0322266Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M10.8127 10.9556H5.854V27.3755H10.8127V10.9556Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M5.85402 10.9556H0V16.4131H5.85404L5.85402 10.9556Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M43.0748 27.5672C41.3228 27.5672 39.7396 27.19 38.3251 26.436C36.9267 25.6819 35.8258 24.647 35.022 23.3314C34.2344 21.9996 33.8406 20.5075 33.8406 18.8548C33.8406 17.2023 34.2344 15.7181 35.022 14.4024C35.8258 13.0708 36.9267 12.0278 38.3251 11.2737C39.7396 10.5196 41.3228 10.1426 43.0748 10.1426C44.8267 10.1426 46.402 10.5196 47.8004 11.2737C49.1987 12.0278 50.2998 13.0708 51.1034 14.4024C51.9071 15.7181 52.309 17.2023 52.309 18.8548C52.309 20.5075 51.9071 21.9996 51.1034 23.3314C50.2998 24.647 49.1987 25.6819 47.8004 26.436C46.402 27.19 44.8267 27.5672 43.0748 27.5672ZM43.0748 24.2458C44.0713 24.2458 44.9715 24.0213 45.7751 23.5721C46.5788 23.1068 47.2056 22.4649 47.6557 21.6467C48.1219 20.8283 48.3549 19.8978 48.3549 18.8548C48.3549 17.812 48.1219 16.8813 47.6557 16.0631C47.2056 15.2449 46.5788 14.611 45.7751 14.1618C44.9715 13.6965 44.0713 13.4638 43.0748 13.4638C42.0782 13.4638 41.1782 13.6965 40.3744 14.1618C39.5708 14.611 38.9359 15.2449 38.4697 16.0631C38.0198 16.8813 37.7947 17.812 37.7947 18.8548C37.7947 19.8978 38.0198 20.8283 38.4697 21.6467C38.9359 22.4649 39.5708 23.1068 40.3744 23.5721C41.1782 24.0213 42.0782 24.2458 43.0748 24.2458Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M65.9975 27.2786L62.7427 22.5856H62.5498H59.1502V27.2786H55.2444V10.4316H62.5498C64.0446 10.4316 65.3384 10.6803 66.4315 11.1777C67.5405 11.6751 68.3924 12.3811 68.9871 13.2957C69.5819 14.2101 69.8792 15.2932 69.8792 16.5447C69.8792 17.7962 69.5737 18.8791 68.963 19.7937C68.3683 20.6923 67.5164 21.3822 66.4074 21.8636L70.1926 27.2786H65.9975ZM65.9252 16.5447C65.9252 15.598 65.6197 14.876 65.009 14.3786C64.3981 13.8652 63.5061 13.6085 62.3327 13.6085H59.1502V19.4809H62.3327C63.5061 19.4809 64.3981 19.2242 65.009 18.7108C65.6197 18.1973 65.9252 17.4753 65.9252 16.5447Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M76.2344 13.6085H70.8337V10.4316H85.541V13.6085H80.1402V27.2786H76.2344V13.6085Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M100.043 13.6085H94.6421V10.4316H109.349V13.6085H103.949V27.2786H100.043V13.6085Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M122.204 27.2786L118.949 22.5856H118.756H115.357V27.2786H111.451V10.4316H118.756C120.251 10.4316 121.545 10.6803 122.638 11.1777C123.747 11.6751 124.599 12.3811 125.193 13.2956C125.788 14.2102 126.086 15.2932 126.086 16.5447C126.086 17.7962 125.78 18.8792 125.169 19.7937C124.575 20.6923 123.723 21.3822 122.614 21.8636L126.399 27.2786H122.204ZM122.131 16.5447C122.131 15.5981 121.826 14.876 121.215 14.3787C120.604 13.8652 119.712 13.6085 118.539 13.6085H115.357V19.4809H118.539C119.712 19.4809 120.604 19.2242 121.215 18.7108C121.826 18.1974 122.131 17.4753 122.131 16.5447Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M140.295 23.6686H132.458L130.964 27.2786H126.962L134.484 10.4316H138.341L145.888 27.2786H141.79L140.295 23.6686ZM139.065 20.7083L136.389 14.2583L133.713 20.7083H139.065Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M163.118 10.4316V27.2786H159.912L151.497 17.0501V27.2786H147.64V10.4316H150.869L159.26 20.6602V10.4316H163.118Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M180.781 24.1018V27.2786H166.027V24.7515L175.284 13.6085H166.219V10.4316H180.42V12.9587L171.185 24.1018H180.781Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M183.286 10.4316H187.192V27.2786H183.286V10.4316Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            d: "M194.694 13.6085H189.293V10.4316H204V13.6085H198.599V27.2786H194.694V13.6085Z",
            fill: "white",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_24681_35469" } }, [
          _c("rect", { attrs: { width: "204", height: "28", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }